.product-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
  color: black;
  font-family: Sofia Pro;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.product-image {
  width: 70px;
  height: auto;
  margin-right: 15px;
}
.product-details {
  flex: 1;
}
.price {
  font-weight: 600;
  padding-top: 0.8rem;
}
.old-price {
  text-decoration: line-through;
  color: theme('colors.brand.gray');
  margin-left: 5px;
}
